<template>
    <div class="setting-sendgrid">
        <edit :fs="sendgridFields" api="settings" id="sendgrid" title="SendGrid Setting"></edit>
    </div>
</template>
<script>
import Edit from "@/components/Edit";
import { sendgridFields } from "@/config";
export default {
    components: {
        Edit
    },
    computed: {
        sendgridFields() {
            return sendgridFields;
        }
    }
};
</script>
<style lang="less">
.setting-sendgrid {
    .md-card {
        max-width: 1000px;
        margin: auto;
    }
}
</style>


